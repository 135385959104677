import React from "react";
import "./ContactLists.scss";
import {Column} from "rbx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeOpen, faHome, faPhone, faVial} from "@fortawesome/free-solid-svg-icons";

export function ContactLists(props) {
    return(
        <Column.Group className={ props.mobileOnly ? "ContactListsMobile" : "ContactLists" }>
            <Column></Column>
            <Column>
                <div>
                    <FontAwesomeIcon className="faIcon" icon={faVial} />
                    BioX Technologies s.r.o. <br/>
                    Laboratory at VUP <br/>
                    Priemyselná 4 <br/>
                    82108 Bratislava <br/>
                    Slovakia
                </div>
            </Column>
            {/* <Column>
                <div>
                    <FontAwesomeIcon className="faIcon" icon={faHome} />
                    BioX Technologies s.r.o. <br/>
                    Nové Záhrady IV / 10 <br/>
                    82105 Bratislava <br/>
                    Slovakia
                    <br/><br/>
                    <FontAwesomeIcon className="faIcon" icon={faPhone} />
                    +421 902 828 933
                    <br/><br/>
                    <FontAwesomeIcon className="faIcon" icon={faEnvelopeOpen} />
                    info@biox.sk
                </div>
            </Column> */}
        </Column.Group>
    )
}