import {Link} from "react-router-dom";
import "./ProjectCard.scss";

export function ProjectCard(props) {
    let imageBuffer = [];
    if(props.bodyImages) {
        for(let i = 0; i < props.bodyImages.length; i++) {
            imageBuffer.push(<img src={props.bodyImages[i]}></img>)
        }
    }
    return(
        <div className="ProjectCard">
            <div className="image" style={{backgroundImage: `url(${props.image})`, backgroundPosition: `center`}}></div>
            <div className="content">
                <h1>{props.title}</h1>
                <p className={ props.shortenText ? "fade" : "non-fade"}>{props.description}</p>
                { props.link ? <Link to={props.link}>Read more</Link> : ""}
                { props.bodyImages ? <div className="imageShowcase">{imageBuffer}</div> : "" }
            </div>
        </div>
    )
}