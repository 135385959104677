import {
    Container
} from "rbx";

import "./Article.scss";

export function Article(props) {

    return(
        <div className="Article">
            <Container>
                <div className="separator-title">
                    <h1>{props.title}</h1>
                </div>
                {props.children}
            </Container>
        </div>
    );

}